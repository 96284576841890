<template>
  <div class="sourceForm">
    <div class="sourceAdd">
      <form-panel
        ref="formPanel"
        :form="form"
        v-bind="submitConfig"
        :submitText="'完成'"
        :submitBefore="submitBefore"
        :submitError="submitError"
      >
        <el-form-item
          label="数据源名称"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
          prop="name"
        >
          <v-input
            :width="400"
            placeholder="请输入数据源名称"
            v-model="form.name"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="数据库连接配置（jdbc）"
          :rules="[
            {
              required: true,
              message: '请输入数据库连接配置',
              trigger: 'blur',
            },
          ]"
          prop="url"
        >
          <v-input
            :width="400"
            placeholder="请输入数据库连接配置"
            v-model="form.url"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="用户名"
          :rules="[
            {
              required: true,
              message: '请输入用户名',
              trigger: 'blur',
            },
          ]"
          prop="username"
        >
          <v-input
            :width="400"
            placeholder="请输入用户名"
            v-model="form.username"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="密码"
          :rules="[
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ]"
          prop="password"
        >
          <v-input
            :width="400"
            placeholder="请输入密码"
            v-model="form.password"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="同步方式"
          :rules="[
            {
              required: true,
              message: '请选择同步方式',
              trigger: 'blur',
            },
          ]"
          prop="mode"
        >
          <v-radio
            :radioObj="radioObj"
            v-model="form.mode"
            @change="change"
          ></v-radio>
        </el-form-item>
        <el-form-item
          label="抽取数据脚本（sql）"
          :rules="[
            {
              required: true,
              validator: validateSql,
              trigger: 'blur',
            },
          ]"
          prop="sql"
        >
          <v-input
            :width="320"
            placeholder="请输入数据库连接配置"
            v-model="form.sql"
          ></v-input>

          <v-button class="ml" text="预览" @click="watchInfo()"> </v-button>
        </el-form-item>
        <div class="workSheet" v-if="requestList.length > 0">
          <div class="workSheet-title">抽取字段预览：</div>
          <v-table :tableData="requestList" ref="vTable" :headers="headers">
            <template #operateTeSlot="scope">
              <div class="opera-btn">
                <v-select
                  :multiple="true"
                  :width="116"
                  :placeholder="'主键ID'"
                  :options="options"
                  v-model="requestList[scope.row.$index].value"
                  @change="toEdit"
                  @remove="remove"
                />
              </div>
            </template>
          </v-table>
        </div>
      </form-panel>
    </div>
  </div>
</template>

<script>
import {
  sourceDetailUrl,
  addSourceUrl,
  watchInfo,
  getSourceDetail,
} from "./api.js";
export default {
  data() {
    return {
      radioObj: [
        { name: 0, value: "全量同步" },
        { name: 1, value: "增量同步" },
      ],
      errForm: {},
      options: [
        {
          label: "主键ID",
          value: "主键ID",
          disabled: false,
        },
        {
          label: "自增ID",
          value: "自增ID",
          disabled: false,
        },
        {
          label: "更新日期",
          value: "更新日期",
          disabled: false,
        },
      ],
      submitConfig: {
        queryMethod: "post",
        submitUrl: addSourceUrl,
      },
      form: {
        url: "",
        name: "",
        password: "",
        sql: "",
        username: "",
        mode: null,
        type: 0,
      },
      extra: {
        fieldLists: [],
        primaryKey: "",
        incrementKey: "",
        updateKey: "",
      },
      headers: [
        {
          prop: "field",
          label: "名称",
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "type",
          label: "类型",
        },
      ],
      requestList: [],
    };
  },
  mounted() {
    // 判断是否有id， 有id 为编辑
    if (this.$route.query.id) {
      this.$setBreadList("编辑");
      this.getDetail();
    } else {
      this.$setBreadList("新增");
    }
  },
  methods: {
    //选择同步方式状态
    change(val) {
      this.requestList.forEach((item) => {
        item.value = [];
        this.operateStatus();
      });
    },
    operateStatus() {
      if (this.form.mode == 0) {
        this.options.forEach((item) => {
          if (item.value == "主键ID") {
            item.disabled = false;
          } else {
            item.disabled = true;
          }
        });
      } else {
        this.options.forEach((item) => {
          if (item.value != "主键ID") {
            item.disabled = false;
          } else {
            item.disabled = true;
          }
        });
      }
    },
    //点击编辑显示列表显示
    getDetail() {
      // 有id为编辑
      const params = { id: this.$route.query.id };
      this.$axios.get(`${getSourceDetail}`, { params }).then((res) => {
        if (res.code === 200) {
          this.form = JSON.parse(res.data.config);
          this.form.name = res.data.name;
          this.form.mode = res.data.mode;
          this.extra = JSON.parse(res.data.extra);
          this.watchInfo();
        }
      });
    },
    // 点击预览显示列表数据
    watchInfo() {
      this.$axios
        .post(`${watchInfo}`, this.form, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code == 200) {
            res.data.forEach((item, index) => {
              item.disabled = false;
              item.value = [];
              if (item.field === this.extra.primaryKey) {
                item.value.push("主键ID");
                this.options[0].disabled = true;
              }
              if (item.field === this.extra.incrementKey) {
                item.value.push("自增ID");
                this.options[1].disabled = true;
              }
              if (item.field === this.extra.updateKey) {
                item.value.push("更新日期");
                this.options[2].disabled = true;
              }
            });
            this.requestList = res.data;
            this.operateStatus();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 操作下拉选择
    toEdit(item) {
      this.options.forEach((items, index) => {
        if (item.includes(items.value)) {
          items.disabled = true;
        }
      });
    },
    //操作下拉选择移除
    remove(item) {
      this.options.forEach((items, index) => {
        if (item.includes(items.value)) {
          items.disabled = false;
        }
      });
    },
    //校验抽取数据脚本
    validateSql(rule, value, callback) {
      const reg = /where/gi;
      if (reg.test(value)) {
        callback(
          new Error(
            "抽取脚本要求抽取目标表的所有源数据， 不应该出现WHERE条件语句,请注意。"
          )
        );
      } else {
        callback();
      }
    },
    // 表单提交前
    submitBefore() {
      this.errForm = this.form;
      const data = JSON.stringify(this.form);
      console.log(303, this.errForm);
      this.extra.fieldLists = [];
      this.extra.primaryKey = "";
      this.extra.incrementKey = undefined;
      this.extra.updateKey = "";
      this.requestList.forEach((items) => {
        if (items.value.includes("主键ID")) {
          this.extra.primaryKey = items.field;
        }
        if (items.value.includes("自增ID")) {
          this.extra.incrementKey = items.field;
        }
        if (items.value.includes("更新日期")) {
          this.extra.updateKey = items.field;
        }
        const obj = (({ field, size, type, remark }) => ({
          field,
          size,
          type,
          remark,
        }))(items);
        this.extra.fieldLists.push(obj);
        this.form.config = data;
        this.form.extra = JSON.stringify(this.extra);
      });
      const { incrementKey, updateKey, primaryKey } = this.extra;
      if (
        (this.form.mode == 0 && primaryKey === "") ||
        primaryKey === undefined
      ) {
        this.form = this.errForm;
        this.$message.error("请先预览并选择主键ID");
        return false;
      }
      return true;
    },
    //提交失败
    submitError(res) {
      console.log(this.errForm);
      this.form = this.errForm;
      this.$message.error("保存失败");
    },
  },
};
</script>

<style lang="less" scoped>
.sourceForm {
  box-sizing: border-box;
  height: 100%;
  // overflow-y: auto;
  // overflow-x: hidden;
  .sourceAdd {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
  }
  .ml {
    margin-left: 20px;
  }
  .title {
    margin: 0 0 10px 110px;
    font-size: 18px;
    font-weight: bold;
  }
  /deep/ .el-form-item__label {
    width: 210px !important;
  }
  /deep/ textarea {
    height: 150px;
  }
  .workSheet {
    padding: 20px;
    width: 600px;
    height: 350px;
    .workSheet-title {
      text-align: center;
      margin-bottom: 20px;
      font-weight: 700;
    }
  }
}
</style>
